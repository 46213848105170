// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-careers-js": () => import("./../../../src/templates/careers.js" /* webpackChunkName: "component---src-templates-careers-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-cookies-js": () => import("./../../../src/templates/cookies.js" /* webpackChunkName: "component---src-templates-cookies-js" */),
  "component---src-templates-gdpr-js": () => import("./../../../src/templates/gdpr.js" /* webpackChunkName: "component---src-templates-gdpr-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-reference-js": () => import("./../../../src/templates/reference.js" /* webpackChunkName: "component---src-templates-reference-js" */),
  "component---src-templates-references-js": () => import("./../../../src/templates/references.js" /* webpackChunkName: "component---src-templates-references-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */)
}

